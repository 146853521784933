import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Design/react-grid-layout.css';
import './Design/react-resizable.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Study } from './Component/Study';
import { Menu } from './Component/Menu';
import { About } from './Component/About';
import { Hints } from './Component/Hints';

class App extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
		  <Router>
        <Switch>
          <Route path="/hints" component={Hints} />
          <Route path="/about" component={About} />
          <Route path="/study" component={Study} />
          <Route path="/" component={Menu} />
        </Switch>
      </Router>
    );
  }
}

export default App;
