let mapping = {
    "Acidity": "Acidity",
    "Conformers": "Conformers",
    "Basicity": "Basicity",
    "Dipole": "Dipole",
    "Cyclohexane": "Cyclohexane",
    "Resonance": "Resonance",
    "Stereoisomers": "Stereoisomers",
    "Reactive Intermediates": "Reactive Intermediates",
    "Radicals": "Radicals",
    "Cations": "Cations",
    "Anions": "Anions",
    "SN2": "SN2",
    "SN1": "SN1",
    "Nucleophilicity": "Nucleophilicity",
    "Alkene": "Alkene",
    "Alcohol": "Alcohol",
    "Analytical": "Analytical",
    "UV Vis": "UV-vis",
    "IR": "IR",
    "MS": "MS",
    "NMR": "NMR",
    "Aromaticity": "Aromaticity",
    "Aromatic Reactions": "Aromatic Reactions",
    "Diels Alder": "Dies-Alder",
    "Amine": "Amine",
    "Carbonyl": "Carbonyl",
    "Acid Derivatives": "Acid Derivatives",
    "A Sub B Carbonyl": "Alpha Substitution and Beta Dicarbonyl"
}

export function GetQuestions(question_count, exam, categories, deterministicFetch) {
    return new Promise((resolve) => {
        let payload = {
            question_count,
            exam,
            categories,
            deterministicFetch
        };
        console.log(payload);
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "https://rf2d1yvezc.execute-api.us-east-1.amazonaws.com/prod", true);
        xhr.onload = function (e) {
            var response = JSON.parse(e.target.response);
            response.questions = response.questions.map((item) => {
                return ({
                    question: item.question,
                    category: mapping[item.category],
                    solution: item.solution,
                    solution_img: item.solution_img,
                    svg1: item.svg1,
                    svg2: item.svg2,
                    svg3: item.svg3,
                    svg4: item.svg4,
                    svg5: item.svg5,
                })
            });
            resolve(response);
        };
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(payload));
    })
}
