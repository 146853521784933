import React from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';

import GridLayout from 'react-grid-layout';

import '../Design/Study.css'

class ImageSvgComponent extends React.Component {
    render() {
        return (
            <div className="ImageGridCell">
                {
                    (this.props.image === undefined) ?
                        (
                            /*this changes the Loading box features*/
                            <svg className="CompoundImage" fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6z" />
                                <path d="M0 0h24v24H0V0z" fill="none" />
                            </svg>
                        )
                        :
                        (
                            /*this changes the image style of each answer box*/
                            <img style={{ height: this.props.height, width: this.props.height }} draggable="false" src={"data:image/svg+xml;base64, " + btoa(this.props.image)} className="CompoundImage" tabindex="1" />
                        )
                }
            </div>
        )
    }
}

class ImageGrid extends React.Component {
    
    // state = {
    //     showBigPicture: false,
    // };

    render() {
        // layout is an array of objects, see the demo for more complete usage
        const layout = [
            { i: '1', x: 0, y: 0, w: 1, h: 1, maxH: 1 },
            { i: '2', x: 0, y: 1, w: 1, h: 1, maxH: 1 },
            { i: '3', x: 0, y: 2, w: 1, h: 1, maxH: 1 },
            { i: '4', x: 0, y: 3, w: 1, h: 1, maxH: 1 },
            { i: '5', x: 0, y: 4, w: 1, h: 1, maxH: 1 },
        ];
        return (
            <>
            <GridLayout
                className="layout ImageGrid"
                style={{ width: this.props.cellHeight }}
                layout={layout}
                cols={1}
                useCSSTransforms={false}
                margin={[0, 5]}
                rowHeight={this.props.cellHeight}
                width={this.props.cellHeight}
                onDragStop={(e) => this.props.setOrder(e.map(item => item.y))}
            >
                {/* <div key="1" onClick={() => { this.setState({ showBigPicture: !this.state.showBigPicture}) }} ><ImageSvgComponent height={this.props.cellHeight} image={this.props.images[0]} /></div> */}
                
                {/* Each div below is a box that the user can pick up, drag, put down, etc. Try making each <div> a <Button> and see what happens ;) */}
                <div key="1"><ImageSvgComponent height={this.props.cellHeight} image={this.props.images[0]} /></div>
                <div key="2"><ImageSvgComponent height={this.props.cellHeight} image={this.props.images[1]} /></div>
                <div key="3"><ImageSvgComponent height={this.props.cellHeight} image={this.props.images[2]} /></div>
                <div key="4"><ImageSvgComponent height={this.props.cellHeight} image={this.props.images[3]} /></div>
                <div key="5"><ImageSvgComponent height={this.props.cellHeight} image={this.props.images[4]} /></div>
            </GridLayout>

            {/* If you made any of the boxes above a button, you would add the popup div of it here. */}
            
            {/*             
            <div>
            <Modal
                show={this.state.showBigPicture}
                onHide={() => this.setState({ showBigPicture: false })}
                >
            <Modal.Header closeButton>
            <Modal.Title>COMPOUND IMAGE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <img style={{ height: this.props.height*2, width: this.props.height*2 }} draggable="false" src={"data:image/svg+xml;base64, " + btoa(this.props.images[0])} tabindex="1"  />  
            </Modal.Body>
            </Modal>
            </div>
             */}

            </>

        )
    }
}

// Function to render the solution images only to questions that have one
function renderSolutionImage(props) {
    if (props.currentQuestion.solution_img == null) {
        return (<></>)
    } else {
        return (<img src={"data:image/svg+xml;base64, " + btoa(props.currentQuestion.solution_img)} className="SolutionImage" />)
    }
}

// Page that uses data retrieved from the database and renders it as a question with images and ranking dropdowns.
class PortraitVerticalLayout extends React.Component {
    constructor(props) {
        super(props);

        this.question_count = props.question_count

        this.order = [0, 1, 2, 3, 4];

        this.state = {
            ranking: [null, null, null, null, null],
            myVariable: "question text",
            showCorrectCard: false,
            showIncorrectCard: false,
            showInvalidRankingCard: false,
            showHintCard: false,
            numCorrect: 5,
            width: 0, height: 0, // Used to maintain screen dimensions
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this); // Maintain screen dimensions
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    // Calculates how many of the 5 compounds were ranked correctly by comparing the user's answers to the answer key
    calculateScore = (userRankings) => {

        // // The code below is used to determine how many questions are in the correct order
        // let relativePositions = [1, 2, 3, 4, 5].map(item => this.props.questionData.answers[userRankings.indexOf(item)]);
        // let longestArray = relativePositions.reduce((p, c, i) => i ? p[p.length - 1] < c ? p.concat(c)
        //     : p
        //     : [c], []);
        // console.log(relativePositions);

        // This is what Nisha did
        if (userRankings[0] != this.props.questionData.answers[0]) {
            this.state.numCorrect--;
        }
        if (userRankings[1] != this.props.questionData.answers[1]) {
            this.state.numCorrect--;
        }
        if (userRankings[2] != this.props.questionData.answers[2]) {
            this.state.numCorrect--;
        }
        if (userRankings[3] != this.props.questionData.answers[3]) {
            this.state.numCorrect--;
        }
        if (userRankings[4] != this.props.questionData.answers[4]) {
            this.state.numCorrect--;
        }
    }

    // Determines the whether the user's rankings are correct, incorrect, or invalid.
    checkRanking = () => {
        this.state.numCorrect = 5;

        // Stores the user's ranking in an array to check if ranking is invalid
        var userRankings = this.order.map(item => item + 1);

        // Determine if the ranking is correct
        var correctRanking = true;
        for (var i = 0; i < userRankings.length; i++) {
            if (userRankings[i] !== this.props.questionData.answers[i]) {
                correctRanking = false;
            }
        }
        console.log(userRankings,this.props.questionData.answers)

        // Calculates how many out of 5 compounds the user ranked correctly
        this.calculateScore(userRankings);

        // Determines what components should be displayed if the user's answer is correct, incorrect, or invalid
        if (correctRanking) {
            window.gtag('event','submitCorrect',{
                category: this.props.currentQuestion.category
            })
            this.setState({
                showCorrectCard: !this.state.showCorrectCard
            })
        } else {
            window.gtag('event','submitIncorrect',{
                category: this.props.currentQuestion.category
            })
            this.setState({
                showIncorrectCard: !this.state.showIncorrectCard
            })
        }
    }

    // Resets the value of the ranking dropdowns to prepare for a new question
    resetRanks = () => {
        if (document.getElementById('firstimg') != null) {
            document.getElementById('firstimg').value = "1";
        }
        if (document.getElementById('secondimg') != null) {
            document.getElementById('secondimg').value = "1";
        }
        if (document.getElementById('thirdimg') != null) {
            document.getElementById('thirdimg').value = "1";
        }
        if (document.getElementById('fourthimg') != null) {
            document.getElementById('fourthimg').value = "1";
        }
        if (document.getElementById('fifthimg') != null) {
            document.getElementById('fifthimg').value = "1";
        }
    }

    // If an SVG link has an extra '/' at the end, the image will not render properly, so this function removes the '/' from the end of the string if present
    sliceString = () => {
        if (this.props.questionData.images[0] != undefined) {
            if (this.props.questionData.images[0].charAt(this.props.questionData.images[0].length - 1) == '/') {
                this.props.questionData.images[0] = this.props.questionData.images[0].substring(0, this.props.questionData.images[0].length - 1);
            }
        }
        if (this.props.questionData.images[1] != undefined) {
            if (this.props.questionData.images[1].charAt(this.props.questionData.images[1].length - 1) == '/') {
                this.props.questionData.images[1] = this.props.questionData.images[1].substring(0, this.props.questionData.images[1].length - 1);
            }
        }
        if (this.props.questionData.images[2] != undefined) {
            if (this.props.questionData.images[2].charAt(this.props.questionData.images[2].length - 1) == '/') {
                this.props.questionData.images[2] = this.props.questionData.images[2].substring(0, this.props.questionData.images[2].length - 1);
            }
        }
        if (this.props.questionData.images[3] != undefined) {
            if (this.props.questionData.images[3].charAt(this.props.questionData.images[3].length - 1) == '/') {
                this.props.questionData.images[3] = this.props.questionData.images[3].substring(0, this.props.questionData.images[3].length - 1);
            }
        }
        if (this.props.questionData.images[4] != undefined) {
            if (this.props.questionData.images[4].charAt(this.props.questionData.images[4].length - 1) == '/') {
                this.props.questionData.images[4] = this.props.questionData.images[4].substring(0, this.props.questionData.images[4].length - 1);
            }
        }
    }

    render() {
        { this.sliceString() }
        if (this.state.numCorrect == 5) {
            { this.resetRanks() }
        }
        console.log(this.props.questionData)
        return (
            <div className="TemplateLayoutTop" >
                <div style={{ height: this.state.height * 0.19 - 25 }}>
                    <Card className="Question">
                        <Card.Body>
                            {this.props.currentQuestion.question} (Put 1 at the top, 5 at the bottom)
                        </Card.Body>
                    </Card>
                </div>
                <div className="ImageGridContainer">
                    {/* This is the grid container that stores all the answer boxes you can pick up, drag, change order, etc as the user */}
                    <ImageGrid initialRanking={this.props.questionData.answers} setOrder={(order) => { this.order = order }} cellHeight={this.state.height * 0.12} images={this.props.questionData.images} />
                </div>
                <div style={{ height: this.state.height * 0.19 - 25 }} className="TemplateLayoutRow">
                    <Button className="ActionButton" onClick={() => {
                        window.gtag('event','goHome',{
                            category: this.props.currentQuestion.category
                        })
                        window.location.href = '/'
                    }} >
                        Home
                    </Button>
                    <Button className="ActionButton" onClick={() => {
                        window.gtag('event','getHint',{
                            category: this.props.currentQuestion.category
                        })
                        window.open(`/hints#${this.props.currentQuestion.category}`)
                    }}>
                        Hint
                    </Button>
                    <Button className="ActionButton" onClick={() => { this.checkRanking() }} >
                        Submit
                    </Button>
                    <Button className="ActionButton" onClick={() => {
                        window.gtag('event','skip',{
                            category: this.props.currentQuestion.category
                        })
                        if (this.props.nextQuestionAvailable) {
                            this.props.nextQuestion()
                        } else {
                            window.location.href = '/';
                        }
                    }} >
                        Skip
                    </Button>
                </div>

                <Modal
                    show={this.state.showSolution}
                    onHide={() => {
                        this.setState({
                            showSolution: false
                        })
                        if (!this.props.nextQuestionAvailable) {
                            // Close to home if complete
                            window.location.href = '/';
                        }
                        console.log("On Close")
                        this.props.nextQuestion()
                    }}
                >
                    <Modal.Header closeButton>
                        <h3>SOLUTION</h3>
                    </Modal.Header>
                    <Modal.Body>
                        {/* false <h2><solution-text>{this.props.currentQuestion.solution}</solution-text></h2> */}
                        <h2>
                            <solution-text>
                                {renderSolutionImage(this.props)}
                                {this.props.currentQuestion.solution}
                            </solution-text>
                        </h2>
                        <div className="SolutionActionBar">
                {/* ************************Added the try again button when the user clicks show solution  ***********************  */}
                        <Button className="ActionButton" onClick={() => {
                                window.gtag('event','tryAgain',{
                                    category: this.props.currentQuestion.category
                                })
                                this.setState({ showSolution: false })
                            }} >
                                Try Again
                            </Button>
                {/* ********************************************************************************************************  */}
                            <i class="fa fa-home" onClick={() => window.location.href = '/'}></i>
                            {(this.props.nextQuestionAvailable)
                                ?
                                <Button className="NextQuestionButton" onClick={() => {
                                    console.log("On Click")
                                    this.setState({
                                        showSolution: false
                                    })
                                    this.props.nextQuestion()
                                }}>
                                    Next Question
                                </Button>
                                :
                                <></>
                            }
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showCorrectCard}
                    onHide={() => {
                        this.setState({
                            showCorrectCard: false
                        })
                        if (!this.props.nextQuestionAvailable) {
                            // Close to home if complete
                            window.location.href = '/';
                        }
                        console.log("On Close")
                        this.props.nextQuestion()
                    }}
                >
                    <Modal.Header closeButton>
                        <h5>CORRECT!</h5>
                    </Modal.Header>
                    <Modal.Body>
                        {/* false <h2><solution-text>{this.props.currentQuestion.solution}</solution-text></h2> */}
                        <h2>
                            <solution-text>
                                {renderSolutionImage(this.props)}
                                {this.props.currentQuestion.solution}
                            </solution-text>
                        </h2>
                        <div className="SolutionActionBar">
                            <i class="fa fa-home" onClick={event => window.location.href = '/'}></i>
                            <Button className="NextQuestionButton" onClick={()=>{
                                this.setState({
                                    showCorrectCard: false
                                })
                            }}>
                                Hide
                            </Button>
                            {(this.props.nextQuestionAvailable)
                                ?
                                <Button className="NextQuestionButton" onClick={() => {
                                    console.log("On Click")
                                    this.setState({
                                        showCorrectCard: false
                                    })
                                    this.props.nextQuestion()
                                }}>
                                    Next Question
                                </Button>
                                :
                                <></>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                {/* Popup the IncorrectCard Modal after clicking submit*/}
                <Modal
                    show={this.state.showIncorrectCard}
                    onHide={() => this.setState({ showIncorrectCard: false })}
                >
                    <Modal.Header closeButton>
                        <h3>INCORRECT</h3><br></br>
                    </Modal.Header>
                    <Modal.Body>
                        <h2><strong>{this.state.numCorrect}</strong> out of 5 ranks are correct.<br></br>Try again!</h2>
                        <div className="TemplateLayoutRow">
                            <Button className="ActionButton" onClick={() => {
                                window.gtag('event','tryAgain',{
                                    category: this.props.currentQuestion.category
                                })
                                this.setState({ showIncorrectCard: false })
                            }} >
                                Try Again
                            </Button>
                            <Button className="ActionButton" onClick={() => {
                                window.gtag('event','showSolution',{
                                    category: this.props.currentQuestion.category
                                })
                                this.setState({ showSolution: true, showIncorrectCard: false })
                            }} >
                                Show Solution
                            </Button>
                            <Button className="ActionButton" onClick={() => {
                                window.gtag('event','goHome',{
                                    category: this.props.currentQuestion.category
                                })
                                window.location.href = '/'
                            }} >
                                Go Home
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showInvalidRankingCard}
                    onHide={() => this.setState({ showInvalidRankingCard: false })}
                >
                    <Modal.Header closeButton>
                        <h3>INVALID RANKING</h3><br></br>
                    </Modal.Header>
                    <Modal.Body>
                        Please give each compound a unique rank.
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}

export default PortraitVerticalLayout;
