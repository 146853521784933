import React from 'react';
import Card from 'react-bootstrap/Card'

import '../Design/Study.css';

// Page displaying the authors of the application.
export class About extends React.Component {
	render() {
	  return (
      <Card className="AuthorCard">
        <Card.Title>About</Card.Title>
        <Card.Body>
          Ochem Rank originated as a senior design project created by students <strong>Samantha Gant</strong> (BS '20), <strong>Sean Kennedy</strong> (BS '20), <strong>Nisha Rajesh</strong> (BS '20), and <strong>Vishal Rajesh</strong> (BS '20) under the guidance of faculty <strong>Dr. Mihaela Stefan</strong>, <strong>Dr. Michael Biewer</strong>, and <strong>Dr. Ovidiu Daescu</strong>.
          <br></br><br></br>
          The senior design project was continued by students <strong>Zacharia Shoaib</strong> (BS '21), <strong>Narayana Yenukonda</strong> (BS '21), <strong>Raheel Ahmed</strong> (BS '21), <strong>Saman Laleh</strong> (BS '21), and <strong>Umar Kazi</strong> (BS '21).
          <br></br><br></br>
          Special thanks to Justin Miller, Hanghang Wang, Dr. Jey Veerasamy, and Dr. Miguel Razo-Razo for additional support on this project.
        </Card.Body>
      </Card>
		)
	}
}

