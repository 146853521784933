import React from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownMenu from 'react-bootstrap/DropdownMenu';

import '../Design/Study.css';

function intersection_destructive(a, b)
{
  var result = [];
  while( a.length > 0 && b.length > 0 )
  {  
     if      (a[0] < b[0] ){ a.shift(); }
     else if (a[0] > b[0] ){ b.shift(); }
     else /* they're equal */
     {
       result.push(a.shift());
       b.shift();
     }
  }

  return result;
}

let question_categories = {
   "Alpha Substitution and Beta Dicarbonyl"       : [ 102,104,111,117,149,150                                                                   ],
   "Acid Derivatives"                             : [ 93,95,103,109,120                                                                         ],
   "Acidity"                                      : [ 1,12,29,35,38,40,42,43,46,77,94,99,102,104,111,117,121,122,123,124,125,126,127,128,129    ],
   "Alcohol"                                      : [ 37,41,42,45,116,145,146                                                                   ],
   "Alkene"                                       : [ 31,32,33,34,36,39,44,50,57                                                                ],
   "Amine"                                        : [ 91,97,100,101,116,148                                                                     ],
   "Analytical"                                   : [ 61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,79,84,106,107,108,114,118,119,142,143,144    ],
   "Anions"                                       : [ 17,20,29,55,85                                                                            ],
   "Aromatic Reactions"                           : [ 76,80,82,86,87,89,110,113,115                                                             ],
   "Aromaticity"                                  : [ 77,81,83,85,90,147                                                                        ],
   "Basicity"                                     : [ 3,58,91,97,101                                                                            ],
   "Carbonyl"                                     : [ 92,96,98,105,112                                                                          ],
   "Cations"                                      : [ 21,25,26,32,33,39,49,50,60,66,69                                                          ],
   "Conformers"                                   : [ 2,5,7,8,10,53                                                                             ],
   "Cyclohexane"                                  : [ 5,8,10,51,53                                                                              ],
   "Dies-Alder"                                   : [ 78,88,151                                                                                 ],
   "Dipole"                                       : [ 4,6,47,130,131,132,133                                                                    ],
   "IR"                                           : [ 61,71,75,106                                                                              ],
   "MS"                                           : [ 66,69                                                                                     ],
   "NMR"                                          : [ 62,63,64,65,67,68,70,72,73,74,107,108,114,118,119                                         ],
   "Nucleophilicity"                              : [ 22,28,48,56,139,140,141                                                                   ],
   "Radicals"                                     : [ 16,18,23,27,30,59                                                                         ],
   "Reactive Intermediates"                       : [ 16,17,18,20,21,23,25,26,27,29,30,49,59,81,83,90                                           ],
   "Resonance"                                    : [ 9,11,30,134                                                                               ],
   "SN1"                                          : [ 21,25,49,81,83,90                                                                         ],
   "SN2"                                          : [ 19,22,24,28,48,52,56                                                                      ],
   "Stereoisomers"                                : [ 13,14,15,54,135,136,137,138                                                               ],
   "UV-vis"                                       : [ 79,84,142,143,144                                                                         ],
}

var question_exams = {
    "OChem 1 Exam 1"        : [ 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,46,47,53,54,134,135,136,137,138,139,140,141,145,146],
    "OChem 1 Exam 2"        : [ 16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,48,49,50,52,55,56,57,59,60],
    "OChem 1 Final Exam"    : [ 31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,51,58,121,122,123,124,125,126,127,128,129,130,131,132,133],
    "OChem 2 Exam 1"        : [ 61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,106,107,108,114,118,119],
    "OChem 2 Exam 2"        : [ 76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,110,113,115],
    "OChem 2 Final Exam"    : [ 91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,109,111,112,116,117,120,142,143,144,147,148,149,150,151],
    "OChem 1"               : [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,145,146],
    "OChem 2"               : [61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,142,143,144,147,148,149,150,151]
}
question_exams["OChem 1 Final Exam"] = [...question_exams['OChem 1 Exam 1'], ...question_exams['OChem 1 Exam 2'], ...question_exams['OChem 1 Final Exam']]
question_exams["OChem 1 Final Exam"] = [...question_exams['OChem 1 Exam 1'], ...question_exams['OChem 1 Exam 2'], ...question_exams['OChem 1 Final Exam']]

// Menu page of the application that allows users to select exam type, categories, and number of questions to practice.
export class Menu extends React.Component {

    constructor(props) {
        super(props)
        this.state={
            showSelectionError: false,
            validQuestionCount: 0
        }
    }

    getCategories = () => {
        let selectedCategories = document.getElementById('categories');
        let collection = selectedCategories.selectedOptions;

        var output = [];

        for (let i = 0; i < collection.length; i++) {
            output[i] = collection[i].label;
        }

        return output;
    }

    // Parses the user's exam, question category, and number of questiosn selections in the main menu to retrieve the appropriate data
    startStudying = (format) => {
        // Retrieves the user's exam selection
        var examChoice = document.getElementById('exam').value;
        // Stores user's exam selection in variable exam to be passed to as a parameter to server call GetQuestions in serverCalls.js
        // If no exam was selected, pass null

        if (examChoice == "true") {
            var exam = null;
        } else {
            exam = examChoice;
        }

        if (!document.getElementById('question_count')) {
            this.setState({showSelectionError:true});
            return;
        }

        // Retrieves the user's number of questions selection and stores in variable question_count to be passed to as a parameter to server call GetQuestions in serverCalls.js
        var question_count = document.getElementById('question_count').value;
        if (question_count=="") {
            this.setState({showSelectionError:true});
            return;
        }

        // Retrieves the user's question category selection and stores in variable categoryList
        var categoryList = this.getCategories();

        // If no category was seelected, pass null
        if (categoryList.length == 1 && categoryList[0] == "Select at least one category") {
        } else {
            var categories = [];
            if (categoryList.length == 1) {
                categories[0] = categoryList[0];
            } else {
                for (let i = 0; i < categoryList.length - 1; i++) {
                    categories[i] = categoryList[i + 1];
                }
            }
        }

        // Make sure that either categories or exam are selected
        console.log(categories,exam)
        if (categories==null && exam==null) {
            this.setState({showSelectionError:true});
            return;
        }

        var url = `/study?format=${format}&question_count=${question_count}`;
        if (categories != null) {
            url += `&categories=${categories}`
        }
        if (exam != null) {
            url += `&exam=${exam}`
        }
        this.props.history.push(url)
    }

    ValidQuestionCount = (props) => {
        if (props.count === 0) {
            return (
                <div style={{color:'red'}}>
                    There are no questions meeting these criteria.
                </div>
            )
        }
        let values = Array.from(new Array(props.count), (x, i) => i + 1) 
        return ( 
            <>
                <div>Select the number of questions</div>
                <select id="question_count" className="menu-dropdown" >
                    {
                        values.map(value=>
                            <option value={value}>{JSON.stringify(value)}</option>
                        )
                    }
                </select>
            </>
        )
    }

    updateValidCategories = () => {
        
        var examChoice = document.getElementById('exam').value;
        if (examChoice == "true") {
            examChoice = undefined;
        }

        let categoryTitles = [
            "Acidity",
            "Conformers",
            "Basicity",
            "Dipole",
            "Cyclohexane",
            "Resonance",
            "Stereoisomers",
            "Reactive Intermediates",
            "Radicals",
            "Cations",
            "Anions",
            "SN2",
            "SN1",
            "Nucleophilicity",
            "Alkene",
            "Alcohol",
            "Analytical",
            "UV-vis",
            "IR",
            "MS",
            "NMR",
            "Aromaticity",
            "Aromatic Reactions",
            "Dies-Alder",
            "Amine",
            "Carbonyl",
            "Acid Derivatives",
            "Alpha Substitution and Beta Dicarbonyl",
        ]

        let validCat = categoryTitles.filter(category=>{
            let exam_list = question_exams[examChoice];
            let catg_list = question_categories[category];
            return exam_list.filter(value => catg_list.includes(value)).length > 0
        })
        console.log(validCat)

        this.setState({
            validCategories: validCat
        })

    }

    updateValidQuestionCount = () => {
        
        var examChoice = document.getElementById('exam').value;
        if (examChoice == "true") {
            examChoice = undefined;
        }

        let categoryList = this.getCategories();
        var categories;
        if (categoryList.length == 1 && categoryList[0] == "Select at least one category") {
        } else {
            categories = [];
            if (categoryList.length == 1) {
                categories[0] = categoryList[0];
            } else {
                for (let i = 0; i < categoryList.length - 1; i++) {
                    categories[i] = categoryList[i + 1];
                }
            }
        }

        if (examChoice != undefined && categories != undefined && categories.length !== 0) {
            let examQuestions = question_exams[examChoice];
            var categoriesQuestions = []
            categoryList.forEach(category=>{
                categoriesQuestions = [...new Set([...question_categories[category], ...categoriesQuestions])]
            })
            let validQuestions = examQuestions.filter(question => categoriesQuestions.includes(question))
            this.setState({validQuestionCount: validQuestions.length})
        } else if (examChoice != undefined) {
            let examQuestions = question_exams[examChoice];
            this.setState({validQuestionCount: examQuestions.length})
        } else if (categories != undefined && categories.length !== 0) {
            var categoriesQuestions = []
            categoryList.forEach(category=>{
                categoriesQuestions = [...new Set([...question_categories[category], ...categoriesQuestions])]
            })
            this.setState({validQuestionCount: categoriesQuestions.length})
        } else {
            this.setState({validQuestionCount: 0})
        }

    }

    ValidCategories = () => {
        return (
            <>
                {
                    this.state.validCategories.map(category=>{
                        return <option value={`${category}`}>{`${category}`}</option>
                    })
                }
            </>
        )
    }

    render() {
        return (
            <div>
                <Image className="ORankLogo" src='logo2.svg' />
                <div className="DropdownCategory">
                    <div>Select a course</div>
                    <select id="exam" className="menu-dropdown" onChange={this.updateValidCategories}>
                        <option value=" "></option>
                        <option value="OChem 1">OChem I</option>
                        <option value="OChem 2">OChem II</option>
                    </select>
                </div>
                {
                    (this.state.validCategories) ?
                        <>
                        <div className="DropdownCategory">
                            <div>Select at least one category</div>
                            <div style={{color:'red'}}>To select multiple categories hold CTRL/CMD</div>
                            <select id="categories" className="menu-dropdown" multiple onChange={this.updateValidQuestionCount}>
                                <this.ValidCategories />
                            </select>
                        </div>
                        <div className="DropdownCategory">
                            <this.ValidQuestionCount count={this.state.validQuestionCount} />
                        </div>
                        </>
                        :
                        <></>
                }
                <div className="GoButton">
                    <i class="fa fa-arrow-circle-right" onClick={() => this.startStudying("PortraitVerticalLayout")}></i>
                </div>
                <div className="FeedbackRow">
                    <h4><feedback-text>Have feedback for us? Click <a href="https://forms.gle/dyjTVeNDuN62cXZd8" target="_blank">here</a>!</feedback-text></h4>
                </div>
                <div class="footer">
                    <div class="container text-center">
                        <Row>
                            <Col xs={12} sm={4} className="footer-wrapper text-left">
                                <ul class="list-unstyled">
                                    <li>
                                        <a href="/about">
                                            <quick-link>About</quick-link>
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Modal
                    show={this.state.showSelectionError}
                    onHide={()=>this.setState({showSelectionError:false})}
                >
                    <Modal.Header closeButton>
                        <h3>ERROR</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h2>Please select at least 1 item in Exams and Categories and select a question count.</h2>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
