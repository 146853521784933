import React from 'react';
import queryString from 'query-string';

import { GetQuestions } from '../serverCalls.js';

import '../Design/Study.css'
import PortraitVerticalLayout from './PortraitVerticalLayout';

Array.prototype.shuffle = function () {
    var k, t, len;
  
    len = this.length;
  
    if (len < 2) {
      return this;
    }
  
    while (len) {
      k = Math.floor(Math.random() * len--);
      t = this[k];
  
      while (k < len) {
        this[k] = this[++k];
      }
  
      this[k] = t;
    }
  
    return this;
  };

// Performs the data retrieval from the database using GetQuestions API call in serverCalls.js.
export class Study extends React.Component {

    constructor(props) {
        super(props);

        // Parses the user's selections of exam, question categories, and number of question to retriev the proper data via server calls
        this.urlArgs = queryString.parse(this.props.location.search);
        this.urlArgs.question_count = parseInt(this.urlArgs.question_count);
        var categoryList = this.urlArgs.categories === undefined ? undefined : this.urlArgs.categories.split(",");
        var stringedExam = this.urlArgs.exam === undefined ? undefined : this.urlArgs.exam;

        this.state = {
            format: this.urlArgs.format,
            exam: stringedExam,
            question_count: this.urlArgs.question_count,
            categories: categoryList,
            questionData:{
                answers: [],
                images: []
            },
            questionCounter: 0,
            questions: [
                {
                    question: undefined,
                    solution: undefined,
                    solution_img: undefined,
                    svg1: undefined,
                    svg2: undefined,
                    svg3: undefined,
                    svg4: undefined,
                    svg5: undefined
                }
            ]
        }
    }

    componentDidMount = () => {
        this.fetchQuestion();
    }

    formats = {
        PortraitVerticalLayout: PortraitVerticalLayout
    }

    loadNextQuestion = async() => {
        this.setState({
            questionCounter: this.state.questionCounter + 1
        }, () => {
            // Callback
            this.shuffleRanking()
        })
    }

    fetchQuestion = async() => {

        window.gtag('event','fetchQuestions',{
            exam: this.state.exam,
            categories: this.state.categories,
            question_count: this.state.question_count
        })

        // Fetch the next question from cache, server, etc.
        let deterministicFetch = this.urlArgs.deterministic!==undefined;
        var getQuestionsResponse;
        if (this.state.exam === "OChem 1 Final Exam") {
            var exam1 = GetQuestions(this.state.question_count,"OChem 1 Final Exam",this.state.categories,deterministicFetch);
            var exam2 = GetQuestions(this.state.question_count,"OChem 1 Exam 1",this.state.categories,deterministicFetch);
            var exam3 = GetQuestions(this.state.question_count,"OChem 1 Exam 2",this.state.categories,deterministicFetch);
            [exam1, exam2, exam3] = await Promise.all([exam1,exam2,exam3]);
            let question_count = this.state.question_count || (exam1.question_count+exam2.question_count+exam3.question_count)
            console.log(exam1,exam2,exam3);
            getQuestionsResponse = {
                statusCode: exam1.statusCode,
                question_count: question_count,
                questions: [...exam1.questions, ...exam2.questions, ...exam3.questions].shuffle().slice(0,question_count),
            }
        } else if (this.state.exam === "OChem 2 Final Exam") {
            var exam1 = GetQuestions(this.state.question_count,"OChem 2 Final Exam",this.state.categories,deterministicFetch);
            var exam2 = GetQuestions(this.state.question_count,"OChem 2 Exam 1",this.state.categories,deterministicFetch);
            var exam3 = GetQuestions(this.state.question_count,"OChem 2 Exam 2",this.state.categories,deterministicFetch);
            [exam1, exam2, exam3] = await Promise.all([exam1,exam2,exam3]);
            let question_count = this.state.question_count || (exam1.question_count+exam2.question_count+exam3.question_count)
            console.log(exam1,exam2,exam3);
            getQuestionsResponse = {
                statusCode: exam1.statusCode,
                question_count: question_count,
                questions: [...exam1.questions, ...exam2.questions, ...exam3.questions].shuffle().slice(0,question_count),
            }
        } else {
            getQuestionsResponse = await GetQuestions(this.state.question_count,this.state.exam,this.state.categories,deterministicFetch)
        }

        // If GetQuestions was successful, it will return an array of questions. Need to set the currentQuestion to the first element of the array, pop the head of the array, and make nextQuestion the new first element of the array
        if (getQuestionsResponse.statusCode === 200) {
            if(getQuestionsResponse.questions.length > 0) {
                this.setState({questions: getQuestionsResponse.questions});
            } else {
                alert(`${this.state.exam} and ${this.state.categories} are an invalid combination, try something else.`)
            }
        } else {
            // Failed to retrieve data from the database- log error
            console.error(`GetQuestions Failed`);
            console.error(getQuestionsResponse)
            return;
        }

        this.shuffleRanking();
        // Sets the questionData states "answers" and "images" to the shuffled ranking key and the corresponding compound images respectively, which will be passed to PortraitVerticalLayout.js to be rendered
        // this.setState({questionData:this.state.questionData})
        return;
    }

    // Function that randomly shuffles the contents of an integer array
    shuffle = (ranking) => {
        ranking.sort(() => Math.random() - 0.5);
        return ranking;
    } 

    shuffleRanking = () => {
        var ranking = [1,2,3,4,5];

        // The newly shuffled order to display the compound images. This is also the answer key to the question.
        var shuffledRanking = this.shuffle(ranking);
       
        // Logging the answer to current question for debugging purposes.
        console.log(shuffledRanking)

        // An array that holds the compound images in the order of the answer key, shuffledRanking.

        var compoundImages = shuffledRanking.map(item=>
            this.state.questions[this.state.questionCounter][`svg${item}`]
        );
        this.setState({
            questionData: {
                images: compoundImages,
                answers: shuffledRanking
            }
        })
    }

    render() {
        console.log(this.state.questions)
        console.log(this.state.questionCounter);
        if (this.state.questionData === null) {
            return (
                <></>
            )
        } else {
            // Use the format specified in this.state.format
            const LayoutFormat = this.formats[this.state.format];
            // Display the images in the right order
            return (
                <LayoutFormat 
                    questionData={this.state.questionData} 
                    currentQuestion={this.state.questions[this.state.questionCounter]} 
                    nextQuestion={this.loadNextQuestion} 
                    categoryData={this.state.categories} 
                    nextQuestionAvailable={this.state.questionCounter<this.state.questions.length-1}
                />
            )
        }
    }
}
